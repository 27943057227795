// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

const wireAccessPositionFlipUp = [
  {
    name: "FlipUp Position",
    required: false,
    multiselect: false,
    dropdown: true,
    options: [
      {
        name: "Left",
        thumbnail: "/assets/components/FlipUpLeft.png",
        value: "Left",
      },
      {
        name: "Center",
        thumbnail: "/assets/components/FlipUpCenter.png",
        value: "Center",
      },
      {
        name: "Right",
        thumbnail: "/assets/components/FlipUpRight.png",
        value: "Right",
      },
    ],
  },
];

const sideTableLegRules = [
  // Colors
  {
    type: replacePart,
    condition: [{ feature: "Legs", values: ["Colors"] }],
    node: "leg",
    pid: "63032f315aa214900a4ba83e",
  },
  // Connect
  {
    type: replacePart,
    condition: [{ feature: "Legs", values: ["Connect"] }],
    node: "leg",
    pid: "63032f54057d9f316208ecb1",
  },
  // Collaborate
  {
    type: replacePart,
    condition: [{ feature: "Legs", values: ["Collaborate"] }],
    node: "leg",
    pid: "63032e9621d94b93ab6b285f",
  },
  // Synergy
  {
    type: replacePart,
    condition: [{ feature: "Legs", values: ["Synergy"] }],
    node: "leg",
    pid: "63032fc9748e752da58dc7ec",
  },
  // Perform
  {
    type: replacePart,
    condition: [{ feature: "Legs", values: ["Perform"] }],
    node: "leg",
    pid: "63032f77748e752da58dc7bd",
  },
  // Switch Angular
  {
    type: replacePart,
    condition: [{ feature: "Legs", values: ["Switch Angular"] }],
    node: "leg",
    pid: "63032f9a9c4df171f5b2f9dc",
  },
];

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "1500x600",
            size: 1,
            Length: 1500,
            Depth: 600,
            id: "cabin_main_1500x600",
            thumbnail: "/assets/system/Cabin1500x600-900x450.png",
            productId: "63032dc95aa214900a4ba7ac",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },

          {
            name: "1500x750",
            size: 4,
            Length: 1500,
            Depth: 750,
            id: "cabin_main_1500x750",
            thumbnail: "/assets/system/Cabin1500x750-900x450.png",
            productId: "630371a76c77b9c1b05ba523",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },

          {
            name: "1800x750",
            size: 10,
            Length: 1800,
            Depth: 750,
            id: "cabin_main_1800x750",
            thumbnail: "/assets/system/Cabin1800x750-900x450.png",
            productId: "6304613ce8e66321ae0392ec",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
          {
            name: "1800x900",
            size: 16,
            Length: 1800,
            Depth: 900,
            id: "cabin_main_1800x900",
            thumbnail: "/assets/system/Cabin1800x900-900x450.png",
            productId: "63047547ff11d27bcfbcc12d",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
        ],
      },
    ],
  },
  {
    name: "Side Table",
    multiSelect: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        multiSelect: false,

        options: [
          {
            name: "None",
            thumbnail: "/assets/components/none.jpg",
            pid: "",
            rules: [
              {
                type: hide,
                node: ["cabin_1050_450"],
              },
              ...sideTableLegRules,
            ],
          },
          {
            name: "900 x 450",
            thumbnail: "/assets/system/side-900x450.png",
            rules: [
              {
                type: show,
                node: ["cabin_1050_450"],
              },
              {
                type: replacePart,
                node: ["cabin_1050_450"],
                pid: "63032e37748e752da58dc788",
              },
              ...sideTableLegRules,
            ],
          },
          {
            name: "1050 x 450",
            thumbnail: "/assets/system/side-1050x450.png",
            rules: [
              {
                type: show,
                node: ["cabin_1050_450"],
              },
              {
                type: replacePart,
                node: ["cabin_1050_450"],
                pid: "63032e1121d94b93ab6b2844",
              },
              ...sideTableLegRules,
            ],
          },
          {
            name: "1200 x 450",
            thumbnail: "/assets/system/side-1200x450.png",
            rules: [
              {
                type: show,
                node: ["cabin_1050_450"],
              },
              {
                type: replacePart,
                node: ["cabin_1050_450"],
                pid: "63032e5d057d9f316208ec31",
              },
              ...sideTableLegRules,
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Legs",
    multiSelect: true,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "Legs",
            required: false,
            options: [
              {
                name: "Colors",
                thumbnail: "/assets/components/ColorsSharing.png",
                enableFeatures: ["Color Options"],
                rules: [
                  //main table rules
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x600"] },
                    ],
                    node: "leg001",
                    pid: "630330d2057d9f316208ed1c",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x750"] },
                    ],
                    node: "leg001",
                    pid: "630375e2ef3156d696bcfaf3",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x750"] },
                    ],
                    node: "leg001",
                    pid: "630464e8104c19ba3e4a34b3",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x900"] },
                    ],
                    node: "leg001",
                    pid: "6304bc9b3a04bf4d530d258c",
                  },

                  //side table rules
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x600"] },
                    ],
                    node: "leg",
                    pid: "63032f315aa214900a4ba83e",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x750"] },
                    ],
                    node: "leg",
                    pid: "63032f315aa214900a4ba83e",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x750"] },
                    ],
                    node: "leg",
                    pid: "63032f315aa214900a4ba83e",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x900"] },
                    ],
                    node: "leg",
                    pid: "63032f315aa214900a4ba83e",
                  },

                  {
                    type: show,
                    node: "beam",
                  },
                  {
                    type: show,
                    node: "beam1",
                  },
                ],
              },
              {
                name: "Connect",
                thumbnail: "/assets/components/FloatSharing.png",
                disableFeatures: ["Color Options"],

                rules: [
                  //main table rules
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x600"] },
                    ],
                    node: "leg001",
                    pid: "630330f35aa214900a4ba910",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x750"] },
                    ],
                    node: "leg001",
                    pid: "630375ab6e7dd9c04b3b520d",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x750"] },
                    ],
                    node: "leg001",
                    pid: "630463fd1d3a52b025403123",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x900"] },
                    ],
                    node: "leg001",
                    pid: "6304bc73900e57cb242a7be2",
                  },

                  //side table rules
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x600"] },
                    ],
                    node: "leg",
                    pid: "63032f54057d9f316208ecb1",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x750"] },
                    ],
                    node: "leg",
                    pid: "63032f54057d9f316208ecb1",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x750"] },
                    ],
                    node: "leg",
                    pid: "63032f54057d9f316208ecb1",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x900"] },
                    ],
                    node: "leg",
                    pid: "63032f54057d9f316208ecb1",
                  },
                  {
                    type: show,
                    node: "beam",
                  },
                  {
                    type: show,
                    node: "beam1",
                  },
                ],
              },
              {
                name: "Collaborate",
                thumbnail: "/assets/components/CollaborateSharing.png",
                disableFeatures: ["Color Options"],

                rules: [
                  //main table rules
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x600"] },
                    ],
                    node: "leg001",
                    pid: "6303300021d94b93ab6b28d4",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x750"] },
                    ],
                    node: "leg001",
                    pid: "63037554ba6caec3e5fde151",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x750"] },
                    ],
                    node: "leg001",
                    pid: "630463bfa0ddd33b5e30b85d",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x900"] },
                    ],
                    node: "leg001",
                    pid: "6304bc123a04bf4d530d2544",
                  },

                  //side table rules
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x600"] },
                    ],
                    node: "leg",
                    pid: "63032e9621d94b93ab6b285f",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x750"] },
                    ],
                    node: "leg",
                    pid: "63032e9621d94b93ab6b285f",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x750"] },
                    ],
                    node: "leg",
                    pid: "63032e9621d94b93ab6b285f",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x900"] },
                    ],
                    node: "leg",
                    pid: "63032e9621d94b93ab6b285f",
                  },
                  {
                    type: show,
                    node: "beam",
                  },
                  {
                    type: show,
                    node: "beam1",
                  },
                ],
              },
              {
                name: "Synergy",
                thumbnail: "/assets/components/SynergySharing.png",
                disableFeatures: ["Color Options"],

                rules: [
                  //main table rules
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x600"] },
                    ],
                    node: "leg001",
                    pid: "63033152748e752da58dc8c9",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x750"] },
                    ],
                    node: "leg001",
                    pid: "630376b16e7dd9c04b3b5264",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x750"] },
                    ],
                    node: "leg001",
                    pid: "6304653e650bfc3291150fe2",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x900"] },
                    ],
                    node: "leg001",
                    pid: "6304bcf811fce3e8b75850ea",
                  },

                  //side table rules
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x600"] },
                    ],
                    node: "leg",
                    pid: "63032fc9748e752da58dc7ec",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x750"] },
                    ],
                    node: "leg",
                    pid: "63032fc9748e752da58dc7ec",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x750"] },
                    ],
                    node: "leg",
                    pid: "63032fc9748e752da58dc7ec",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x900"] },
                    ],
                    node: "leg",
                    pid: "63032fc9748e752da58dc7ec",
                  },
                  {
                    type: show,
                    node: "beam",
                  },
                  {
                    type: show,
                    node: "beam1",
                  },
                ],
              },
              {
                name: "Perform",
                thumbnail: "/assets/components/PerformSharing.png",
                disableFeatures: ["Color Options"],

                rules: [
                  //main table rules
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x600"] },
                    ],
                    node: "leg001",
                    pid: "63033112546416e328392e2e",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x750"] },
                    ],
                    node: "leg001",
                    pid: "63037674ef3156d696bcfb1e",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x750"] },
                    ],
                    node: "leg001",
                    pid: "6304651aa0ddd33b5e30b8c0",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x900"] },
                    ],
                    node: "leg001",
                    pid: "6304bcc93a04bf4d530d25a8",
                  },

                  //side table rules
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x600"] },
                    ],
                    node: "leg",
                    pid: "63032f77748e752da58dc7bd",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x750"] },
                    ],
                    node: "leg",
                    pid: "63032f77748e752da58dc7bd",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x750"] },
                    ],
                    node: "leg",
                    pid: "63032f77748e752da58dc7bd",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x900"] },
                    ],
                    node: "leg",
                    pid: "63032f77748e752da58dc7bd",
                  },
                  {
                    type: show,
                    node: "beam",
                  },
                  {
                    type: show,
                    node: "beam1",
                  },
                ],
              },
              {
                name: "Switch Angular",
                thumbnail: "/assets/components/SwitchAngularSharing.png",
                disableFeatures: ["Color Options"],

                rules: [
                  //main table rules
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x600"] },
                    ],
                    node: "leg001",
                    pid: "6303312f546416e328392e42",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x750"] },
                    ],
                    node: "leg001",
                    pid: "630376916e7dd9c04b3b525b",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x750"] },
                    ],
                    node: "leg001",
                    pid: "6305c88694018d511b7c05d8",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x900"] },
                    ],
                    node: "leg001",
                    pid: "6305cd1194018d511b7c0748",
                  },

                  //side table rules
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x600"] },
                    ],
                    node: "leg",
                    pid: "63032f9a9c4df171f5b2f9dc",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1500x750"] },
                    ],
                    node: "leg",
                    pid: "63032f9a9c4df171f5b2f9dc",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x750"] },
                    ],
                    node: "leg",
                    pid: "63032f9a9c4df171f5b2f9dc",
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "id", values: ["cabin_main_1800x900"] },
                    ],
                    node: "leg",
                    pid: "63032f9a9c4df171f5b2f9dc",
                  },

                  {
                    type: hide,
                    node: "beam",
                  },
                  {
                    type: hide,
                    node: "beam1",
                  },
                ],
              },
            ],
          },
          {
            name: "Color Options",
            required: false,
            options: [
              {
                name: "Red",
                thumbnail: "/assets/textures/ColorsLeg/Red-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Red-fl.jpg",
                  },
                ],
              },
              {
                name: "Blue",
                thumbnail: "/assets/textures/ColorsLeg/Blue-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Blue-fl.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/ColorsLeg/Green-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Green-fl.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/ColorsLeg/Yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Yellow.jpg",
                  },
                ],
              },
              {
                name: "Ivory Nandini",
                thumbnail: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
                  },
                ],
              },
              {
                name: "White",
                thumbnail: "/assets/textures/ColorsLeg/White-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/White-fl.jpg",
                  },
                ],
              },
              {
                name: "Mustard Yellow",
                thumbnail: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/ColorsLeg/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Orange.jpg",
                  },
                ],
              },
              {
                name: "Dark Grey",
                thumbnail: "/assets/textures/ColorsLeg/Dark-grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Dark-grey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/ColorsLeg/Silver-grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Silver-grey.jpg",
                  },
                ],
              },
              {
                name: "Black",
                thumbnail: "/assets/textures/ColorsLeg/Black-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Black-fl.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Wire Management",
    multiSelect: true,
    optional: false,
    options: [
      {
        name: "Cabin Table",
        required: false,
        options: [
          {
            name: "Wire Entry",
            required: false,
            options: [
              {
                name: "Snake Wire Entry",
                thumbnail: "/assets/components/snake.png",
                rules: [
                  {
                    type: replacePart,
                    node: ["wire_entry"],
                    pid: "631169cb49289f12eb30f87a",
                  },
                ],
              },
              {
                name: "Vertical Wire Entry",
                thumbnail: "/assets/components/vertical.png",
                rules: [
                  {
                    type: replacePart,
                    node: ["wire_entry"],
                    pid: "631167e2baef7a0a603a4853",
                  },
                ],
              },
            ],
          },
          {
            name: "Wire Access",
            required: false,

            options: [
              {
                name: "FlipUp",
                thumbnail: "/assets/components/flipup.png",

                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "id", values: ["cabin_main_1500x600"] }],
                    node: ["table_top"],
                    pid: "6310b8fa8d49df35dce50d66",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "id", values: ["cabin_main_1500x750"] }],
                    node: ["table_top"],
                    pid: "6310b995a55a2f6d07b7e5cf",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "id", values: ["cabin_main_1800x750"] }],
                    node: ["table_top"],
                    pid: "6310ba61518be1ea3aec4ed2",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "id", values: ["cabin_main_1800x900"] }],
                    node: ["table_top"],
                    pid: "6310badaa55a2f6d07b7e655",
                  },
                ],
              },
              {
                name: "Slider",
                thumbnail: "/assets/components/slider.png",

                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "id", values: ["cabin_main_1500x600"] }],
                    node: ["table_top"],
                    pid: "6310a60468b8d0c76093dffd",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "id", values: ["cabin_main_1500x750"] }],
                    node: ["table_top"],
                    pid: "6310ad374c914e77e86e5feb",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "id", values: ["cabin_main_1800x750"] }],
                    node: ["table_top"],
                    pid: "6310b24551c213a82ee9d71c",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "id", values: ["cabin_main_1800x900"] }],
                    node: ["table_top"],
                    pid: "6310b26f51c213a82ee9d739",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Cabin Table",
        required: true,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Cabin Table",
        required: true,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "Featherlite White Fine",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;
