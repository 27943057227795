// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "Discussion Table",
        required: false,
        options: [
          {
            name: "900 Dia",
            size: 4,
            Dia: 900,
            thumbnail: "/assets/system/discussion900Dia.png",
            productId: "629474e9ba14a473415c2392",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
          {
            name: "1050 Dia",
            size: 6,
            Dia: 1050,
            thumbnail: "/assets/system/discussion1050Dia.png",
            productId: "62a06a422d24a7eed8127aaa",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
          {
            name: "1200 Dia",
            size: 8,
            Dia: 1200,
            thumbnail: "/assets/system/discussion1200Dia.png",
            productId: "62a073a699769ce70fbbd8ee",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
        ],
      },
    ],
  },

  {
    name: "Legs",
    multiSelect: false,
    options: [
      {
        name: "Discussion Table",
        required: false,
        options: [
          {
            name: "Legs",
            required: false,
            options: [
              {
                name: "Colors",
                thumbnail: "/assets/components/Colors-D3.png",
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [900] }],
                    node: "Leg",
                    pid: "62c277c18cf2feddca777ec6",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1050] }],
                    node: "Leg",
                    pid: "62c27895c5109b3a005fe3fe",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1200] }],
                    node: "Leg",
                    pid: "62c279458fe486fb7427c9e4",
                  },
                  // {
                  //   type: show,
                  //   node: "beam",
                  // },
                ],
              },
              {
                name: "Connect",
                thumbnail: "/assets/components/Connect-D3.png",
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [900] }],
                    node: "Leg",
                    pid: "629477e9ef00793c1fc01637",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1050] }],
                    node: "Leg",
                    pid: "62a05c4b88d9072dc0c946f2",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1200] }],
                    node: "Leg",
                    pid: "62a076e51fbbd9ce68bc2043",
                  },
                  // {
                  //   type: show,
                  //   node: "beam",
                  // },
                ],
              },
              {
                name: "Collaborate",
                thumbnail: "/assets/components/Collaborate-D3.png",
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [900] }],
                    node: "Leg",
                    pid: "6294767cbca31fd8590c7ffd",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1050] }],
                    node: "Leg",
                    pid: "62a06ee9002918a27df9be71",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1200] }],
                    node: "Leg",
                    pid: "62a072721fbbd9ce68bc1f4a",
                  },
                  // {
                  //   type: show,
                  //   node: "beam",
                  // },
                ],
              },
              {
                name: "Synergy",
                thumbnail: "/assets/components/Synergy-D3.png",
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [900] }],
                    node: "Leg",
                    pid: "6294781007fff5830043fecf",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1050] }],
                    node: "Leg",
                    pid: "62a05953aeec805717364ab7",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1200] }],
                    node: "Leg",
                    pid: "62a07839d00693621e67e78c",
                  },
                  // {
                  //   type: show,
                  //   node: "beam",
                  // },
                ],
              },
              {
                name: "Perform",
                thumbnail: "/assets/components/Perform-D3.png",
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [900] }],
                    node: "Leg",
                    pid: "63aa9867c00aa0042ecc3c7e",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1050] }],
                    node: "Leg",
                    pid: "63aa9867c00aa0042ecc3c7e",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1200] }],
                    node: "Leg",
                    pid: "62a07a5b4114eaa63d3888c1",
                  },
                  // {
                  //   type: show,
                  //   node: "beam",
                  // },
                ],
              },
              {
                name: "Switch Angular",
                thumbnail: "/assets/components/SwitchAngularSharing.png",
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [900] }],
                    node: "Leg",
                    pid: "63aa997444d4dfc09138e08f ",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1050] }],
                    node: "Leg",
                    pid: "63aa997444d4dfc09138e08f ",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1200] }],
                    node: "Leg",
                    pid: "63aa9a9198e14e42b72e86b7",
                  },
                  // {
                  //   type: show,
                  //   node: "beam",
                  // },
                ],
              },
            ],
          },

          // {
          //   name: "Perform",
          //   thumbnail: "/assets/components/Perform-D3.png",
          //   rules: [
          //     {
          //       type: replacePart,
          //       condition: [{ feature: "noOfSeats", values: [4] }],
          //       node: "Leg",
          //       pid: "628b44f69a6ff1e5b561f771",
          //     },
          //     {
          //       type: replacePart,
          //       condition: [{ feature: "noOfSeats", values: [6] }],
          //       node: "Leg",
          //       pid: "628c918f3b650396b703aa50",
          //     },
          //     {
          //       type: replacePart,
          //       condition: [{ feature: "noOfSeats", values: [8] }],
          //       node: "Leg",
          //       pid: "628c974d03c8f7c338050ee1",
          //     },
          //     {
          //       type: show,
          //       node: "beam",
          //     },
          //   ],
          // },
          // {
          //   name: "Switch Straight",
          //   thumbnail: "/assets/components/SwitchAngularSharing.png",
          //   rules: [
          //     {
          //       type: replacePart,
          //       condition: [{ feature: "noOfSeats", values: [4] }],
          //       node: "Leg",
          //       pid: "62833aabdb62c8c84837ee31",
          //     },
          //     {
          //       type: replacePart,
          //       condition: [{ feature: "noOfSeats", values: [6] }],
          //       node: "Leg",
          //       pid: "628c91ff2f032fac6cccc46b",
          //     },
          //     {
          //       type: replacePart,
          //       condition: [{ feature: "noOfSeats", values: [8] }],
          //       node: "Leg",
          //       pid: "628c978403c8f7c338050f02",
          //     },
          //     {
          //       type: hide,
          //       node: "beam",
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
  {
    name: "Wire Management",
    multiSelect: true,
    optional: false,
    options: [
      {
        name: "Discussion Table",
        required: false,
        options: [
          {
            name: "Wire Entry",
            required: false,
            options: [
              {
                name: "Snake Wire Entry",
                thumbnail: "/assets/components/snake.png",
                rules: [
                  {
                    type: replacePart,
                    node: ["wire_entry"],
                    pid: "63070ee49a93cfccbc7128c7",
                  },
                ],
              },
              {
                name: "Vertical Wire Entry",
                thumbnail: "/assets/components/vertical.png",
                rules: [
                  {
                    type: replacePart,
                    node: ["wire_entry"],
                    pid: "630c46719b7c231aad777c25",
                  },
                ],
              },
            ],
          },
          {
            name: "Wire Access",
            required: false,

            options: [
              {
                name: "FlipUp",
                thumbnail: "/assets/components/flipup.png",

                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [900] }],
                    node: ["table_top"],
                    pid: "630c50e665449225c52e60f7",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1050] }],
                    node: ["table_top"],
                    pid: "630c5062fec9997895ece889",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1200] }],
                    node: ["table_top"],
                    pid: "630c4faf9b7c231aad777eac",
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/components/gromet.jpeg",

                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [900] }],
                    node: ["table_top"],
                    pid: "6305fb10c70f90f77b102088",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1050] }],
                    node: ["table_top"],
                    pid: "6305fa7d257d31cb1c5cc807",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1200] }],
                    node: ["table_top"],
                    pid: "6305f5fbf66d50b2f27c98a4",
                  },

                ],
              },
              {
                name: "Slider",
                thumbnail: "/assets/components/slider.png",

                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [900] }],
                    node: ["table_top"],
                    pid: "63a2b1a67b37961c9c941285",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1050] }],
                    node: ["table_top"],
                    pid: "63a2b1a67b37961c9c941285",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Dia", values: [1200] }],
                    node: ["table_top"],
                    pid: "63a2b1a67b37961c9c941285",
                  },
                  
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Discussion Table",
        required: true,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Discussion Table",
        required: true,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "Featherlite White Fine",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;
