import React, { useCallback, useEffect, useRef, useState } from "react";
import useUserConfiguration from "../utils/updateUserConfiguration";
import { pedestalConstants } from "../const/pedestalConst";
import logo from "../assets/logo.png";

type Rule = {
  type: string;
  node: string[];
  pid: string;
};

const getReplacePart = (partId: string, nodes: string[]) => {
  return {
    parameters: {
      part: partId,
      nodes: nodes,
      position: {
        position: {
          x: 0,
          y: 0,
          z: 0,
        },
        scale: {
          x: "100",
          y: "100",
          z: "100",
        },
        rotation: {
          x: "-90",
          y: 0,
          z: 0,
        },
      },
    },
    type: "ReplacePart",
    id: "6cc506e6-0ce8-41dd-8990-c0430fd1f140",
  };
};

const getUnbindPart = (instance: string) => {
  return {
    parameters: {
      instance: instance,
    },
    type: "Unbind",
    id: "ca7d3ec2-486f-4c51-9a9e-1b27d238343e",
  };
};

const getBindPart = (
  partId: string,
  position?: any,
  rotation?: any,
  instance?: string
) => {
  return {
    parameters: {
      part: partId,
      // nodes: nodes,
      position: {
        position: position || {
          x: 0,
          y: 0,
          z: 0,
        },
        scale: {
          x: "1",
          y: "1",
          z: "1",
        },
        rotation: rotation || {
          x: 0,
          y: 0,
          z: 0,
        },
      },
      instance: instance || "6b49a29e-64f8-4f20-a14e-0a5b4414602c",
    },
    type: "BindPart",
    id: "e7b661df-ca20-4ff9-ab9e-4cee7a8ae88b",
  };
};

const createMaterialRuleJSON = (textureUrl: string, nodeName: string) => {
  return {
    id: "5f979442-e8a8-492b-8b02-75e74c82cbac",
    type: "Manual",
    actions: [
      {
        parameters: {
          material: {
            name: nodeName,
            texture: textureUrl,
            center: {
              x: 0.5,
              y: 0.5,
            },
          },
        },
        type: "ChangeMaterial",
      },
    ],
  };
};

export default function Preview({
  productViewerRef,
}: {
  productViewerRef: React.MutableRefObject<any>;
}) {
  const [productViewer, setProductViewer] = useState<any>();
  const [prevProductId, setPrevProductId] = useState<string>();

  const prevRules = useRef<{
    [key: string]: Rule[];
  }>({});

  const { getSelectedOption, userConfiguration2, getRules } =
    useUserConfiguration();
  const option = getSelectedOption("System", userConfiguration2.type);
  const productId = option && option[0] && option[0].productId;

  const hideNode = useCallback(
    (nodeName: string) => {
      const ruleJSON = {
        id: "4175419f-ace6-4ca4-bf2d-bfe9b53f75c1",
        type: "Manual",
        actions: [
          {
            parameters: {
              node: nodeName, //add correct node here
            },
            type: "HideNode",
            id: "e526d733-de1c-4b6c-bf6a-b61688d2c807",
          },
        ],
      };

      if (productViewer?.viewer) {
        productViewer?.viewer?.runCustomRule(ruleJSON, () => {});
      }
    },
    [productViewer?.viewer]
  );

  const showNode = useCallback(
    (nodeName: string) => {
      let ruleJSON = {
        id: "4175419f-ace6-4ca4-bf2d-bfe9b53f75c1",
        type: "Manual",
        actions: [
          {
            parameters: {
              node: nodeName, //add correct node here
            },
            type: "ShowNode",
            id: "e526d733-de1c-4b6c-bf6a-b61688d2c807",
          },
        ],
      };

      if (productViewer?.viewer) {
        productViewer?.viewer?.runCustomRule(ruleJSON, () => {});
      }
    },
    [productViewer?.viewer]
  );

  const bindPart = useCallback(
    (pid: string, position?: any, rotation?: any, instance?: string) => {
      return new Promise((resolve, reject) => {
        const actions = [getBindPart(pid, position, rotation, instance)];

        let ruleJSON = {
          id: "989f39f2-4f73-4259-a670-1f59f6a1821d",
          iconText: "BindPart",
          type: "Manual",
          actions,
        };

        if (productViewer?.viewer) {
          productViewer?.viewer?.runCustomRule(
            ruleJSON,
            () => {
              resolve(true);
            },
            () => {
              reject(false);
            }
          );
        }
      });
    },
    [productViewer?.viewer]
  );

  const unbindPart = useCallback(
    (instance: string) => {
      return new Promise((resolve, reject) => {
        const actions = [getUnbindPart(instance)];

        let ruleJSON = {
          id: "75fd16c9-70d1-469c-9be9-a94341bd7c8e",
          iconText: "UnbindPart",
          type: "Manual",
          actions,
        };

        if (productViewer?.viewer) {
          productViewer?.viewer?.runCustomRule(
            ruleJSON,
            () => {
              resolve(true);
            },
            () => {
              reject(false);
            }
          );
        }
      });
    },
    [productViewer?.viewer]
  );

  const replacePart = useCallback(
    (nodes: string[], pid: string) => {
      return new Promise((resolve, reject) => {
        const actions = [getReplacePart(pid, nodes)];

        let ruleJSON = {
          id: "4175419f-ace6-4ca4-bf2d-bfe9b53f75c1",
          iconText: "ChangeLeg",
          type: "Menu",
          actions,
        };

        if (productViewer?.viewer) {
          productViewer?.viewer?.runCustomRule(
            ruleJSON,
            () => {
              resolve(true);
            },
            () => {
              reject(false);
            }
          );
        }
      });
    },
    [productViewer?.viewer]
  );

  const applyMaterial = useCallback(
    (nodeName: string, textureUrl: string) => {
      const ruleJSON = createMaterialRuleJSON(textureUrl, nodeName);

      if (productViewer?.viewer) {
        productViewer?.viewer?.runCustomRule(ruleJSON, () => {});
      }
    },
    [productViewer?.viewer]
  );

  const runRules = useCallback(
    async (rules: any[]) => {
      for (let index = 0; index < rules.length; index++) {
        const rule = rules[index];
        const nodes: string[] =
          typeof rule.node === "string" ? [rule.node] : rule.node;

        if (rule.type === "replacePart") {
          try {
            await replacePart(nodes, rule.pid);
          } catch (error) {
            console.log(error);
          }
        }

        if (rule.type === "bindPart") {
          const instance = "6b49a29e-64f8-4f20-a14e-0a5b4414602c";
          if (userConfiguration2.type === "SHARING") {
            const seats = userConfiguration2.noOfSeats;
            const frontStartingPos =
              pedestalConstants.sharedWorkstations.front.pos;
            const backStartingPos =
              pedestalConstants.sharedWorkstations.back.pos;
            let frontPositions = [frontStartingPos];
            let backPositions = [backStartingPos];
            // front pedestal positions
            for (let i = 0; i < seats / 2; i++) {
              const x =
                Number(frontStartingPos.x) +
                i * pedestalConstants.sharedWorkstations.front.increment;

              if (i >= 1) {
                frontPositions.push({
                  ...frontStartingPos,
                  x,
                });
              }
            }
            // back pedestal positions
            for (let i = 0; i < seats / 2; i++) {
              const x =
                Number(backStartingPos.x) +
                i * pedestalConstants.sharedWorkstations.back.increment;

              if (i >= 1) {
                backPositions.push({
                  ...backStartingPos,
                  x,
                });
              }
            }
            frontPositions.forEach(async (pos, index) => {
              const instanceId = `${instance}-front-${index}`;
              // console.log(instanceId, "front");
              try {
                await bindPart(
                  rule.pid,
                  pos,
                  pedestalConstants.sharedWorkstations.front.rotation,
                  instanceId
                );
              } catch (error) {
                console.log(error);
              }
            });
            backPositions.forEach(async (pos, index) => {
              const instanceId = `${instance}-back-${index}`;
              // console.log(instanceId, "front");
              try {
                await bindPart(
                  rule.pid,
                  pos,
                  pedestalConstants.sharedWorkstations.back.rotation,
                  instanceId
                );
              } catch (error) {
                console.log(error);
              }
            });
          } else if (userConfiguration2.type === "NON SHARING") {
            const seats = userConfiguration2.noOfSeats;
            const startingPos =
              pedestalConstants.nonSharedWorkstations.front.pos;
            let allPositions = [startingPos];
            // pedestal positions
            for (let i = 0; i < seats; i++) {
              const x =
                Number(startingPos.x) +
                i * pedestalConstants.nonSharedWorkstations.front.increment;
              if (i >= 1) {
                allPositions.push({
                  ...startingPos,
                  x,
                });
              }
            }
            allPositions.forEach(async (pos, index) => {
              const instanceId = `${instance}-front-${index}`;

              try {
                await bindPart(
                  rule.pid,
                  pos,
                  pedestalConstants.nonSharedWorkstations.front.rotation,
                  instanceId
                );
              } catch (error) {
                console.log(error);
              }
            });
          }
        }

        if (rule.type === "unbind") {
          const instance = "6b49a29e-64f8-4f20-a14e-0a5b4414602c";
          const seats = userConfiguration2.noOfSeats;
          if (userConfiguration2.type === "SHARING") {
            for (let i = 0; i < seats / 2; i++) {
              const instanceId = `${instance}-front-${i}`;
              try {
                await unbindPart(instanceId);
              } catch (error) {
                console.log(error);
              }
            }
            for (let i = 0; i < seats / 2; i++) {
              const instanceId = `${instance}-back-${i}`;
              try {
                await unbindPart(instanceId);
              } catch (error) {
                console.log(error);
              }
            }
          } else if (userConfiguration2.type === "NON SHARING") {
            for (let i = 0; i < seats; i++) {
              const instanceId = `${instance}-front-${i}`;
              try {
                await unbindPart(instanceId);
              } catch (error) {
                console.log(error);
              }
            }
          }
        }

        for (const node of nodes) {
          switch (rule.type) {
            case "hide":
              hideNode(node);
              break;
            case "show":
              showNode(node);
              break;

            case "applyMaterial":
              applyMaterial(node, rule.textureUrl);
              break;
            default:
              break;
          }
        }
      }
    },
    [
      applyMaterial,
      bindPart,
      hideNode,
      replacePart,
      showNode,
      unbindPart,
      userConfiguration2.noOfSeats,
      userConfiguration2.type,
    ]
  );

  useEffect(() => {
    //initialize product viewer
    let productViewer: any = null;
    const dynamicImport = async () => {
      // console.log("444444444", "dynamic import");

      const viewerEle = document.getElementById("3d-viewer");
      if (!viewerEle) return;
      const { HelloViewer, HelloARManager } = await import(
        "helloar/build/helloar.min.js"
      );
      if (process.env.REACT_APP_HELLOAR_STAGING) {
        HelloARManager.getInstance().useDevServer();
      } else {
        HelloARManager.getInstance().useProdServer();
      }

      if (productViewer) {
        productViewer.remove();
      }
      productViewer = new HelloViewer("3d-viewer", null, false, true);
      setProductViewer(productViewer);
      productViewerRef.current = productViewer;

      productViewer.init(
        productId,
        () => {
          setPrevProductId(productId);
        },
        (error: any) => {
          alert("error:" + JSON.stringify(error));
        }
      );
    };
    if (productId) {
      try {
        dynamicImport();
      } catch (error) {}
    }
    return () => {
      if (productViewer) {
        productViewer.remove();
        const x = document.getElementById("3d-viewer");
        if (x) {
          x.innerHTML = "";
        }
      }
    };
  }, [productId, productViewerRef]);

  const hasObjectValueChanged = useCallback((value: any, prevValue: any) => {
    if (JSON.stringify(value) === JSON.stringify(prevValue)) {
      return false;
    }
    return true;
  }, []);

  useEffect(() => {
    (async () => {
      const rules = getRules();
      Object.keys(rules).forEach(async (key) => {
        if (hasObjectValueChanged(rules[key], prevRules.current[key])) {
          if (rules[key].length > 0) {
            try {
              // console.log("run rules", key + ":", rules[key]);
              await runRules(rules[key]);
            } catch (error) {
              console.log(error);
            }
          }
        }
      });
      prevRules.current = rules;
    })();
    //run when userConfiguration2 changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConfiguration2]);

  useEffect(() => {
    (async () => {
      const rules = getRules();
      for (const key in rules) {
        if (rules[key].length > 0) {
          try {
            // console.log("run rules", key + ":", rules[key]);
            await runRules(rules[key]);
          } catch (error) {
            console.log(error);
          }
        }
      }

      prevRules.current = rules;
    })();
    //run when prevProductId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevProductId]);
  return (
    <div
      style={{ width: "100%", height: "100%" }}
      className="flex-grow items-center w-full p-4 mx-auto h-full relative flex align-center justify-center"
    >
      <div
        id="3d-viewer"
        className=" h-full w-full rounded text-gray-600 flex items-center justify-center"
      ></div>
      <img
        src={logo}
        alt="logo"
        className="mr-auto h-8 ml-4 absolute bottom-40 right-4"
      />
    </div>
  );
}
