import React from "react";
import Card from "../Components/Card";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { configurationConstants } from "../store/actions/types";
import { Feature } from "../types/types";
import { makeSessionRequest } from "../utils/makeSessionRequest";
import useUserConfiguration from "../utils/updateUserConfiguration";

export default function PrimaryBar({
  secondaryBarHidden,
  blinkSecondaryBar,

  setSecondaryBarHidden,
}: {
  secondaryBarHidden: boolean;
  blinkSecondaryBar: () => void;

  setSecondaryBarHidden: (hidden: boolean) => void;
}) {
  const dispatch = useAppDispatch();

  const { primaryBarSelection }: { primaryBarSelection: Feature } =
    useAppSelector((state) => state.configuration);
  // console.log(primaryBarSelection, "primaryBarSelection");
  const configData: Feature[] = useAppSelector(
    (state) => state.configData.current
  );
  const { getSelectedOption } = useUserConfiguration();

  const handleClick = (item: any) => {
    if (!secondaryBarHidden && item.name === primaryBarSelection.name) {
      blinkSecondaryBar();
      return;
    }

    if (secondaryBarHidden) {
      setSecondaryBarHidden(false);
      dispatch({
        type: configurationConstants.SET_PRIMARY_BAR_SELECTION,
        payload: item,
      });
      return;
    }

    setSecondaryBarHidden(true);
    console.log(configData, "primarybar");
    setTimeout(() => {
      setSecondaryBarHidden(false);
      dispatch({
        type: configurationConstants.SET_PRIMARY_BAR_SELECTION,
        payload: item,
      });
    }, 500);
  };
  const hasSubFeatures = (features: Feature[]) => {
    return features[0]?.options ? true : false;
  };

  const getLabel = (feature: Feature) => {
    const label = hasSubFeatures(feature.options || [])
      ? feature.options
          .map((subFeature) => getSelectedOption(feature.name, subFeature.name))
          .map((option) => option.map((option) => option.name).join(","))
          .filter((option) => option)
          .join(" ")
      : getSelectedOption(feature.name, "")
          .map((option) => option.name)
          .join(",");
    return label || "---";
  };

  const formatCardName = (name:string, label:string) => {
    const specialNames = ["Legs", "Powder Coat", "Laminate"];
    const includesSpecialSubstring = ["Glass", "Fabric"].some(substring => label.includes(substring));
    
    // Check for Privacy with glass or fabric in the label
    if (name === "Privacy" && includesSpecialSubstring) {
        return ["Glass Color", "Fabric Color"].includes(name) ? `${name}*` : name;
    }

    // Original condition for other special names
    if (specialNames.includes(name) || (includesSpecialSubstring && label !== "---")) {
        return `${name}*`;
    }

    return name;
};


  return (
  <div
      style={{ borderColor: "#DCDCDC", backgroundColor: "#f5f5f5" }}
      className="z-10 absolute bottom-0 overflow-y-auto p-4 pb-2 flex flex-row w-full border-t border-r-2"
    >
      {configData.map((item, idx) => (
        <div key={item.name} className="mr-4">
          <Card
            key={idx}
            disabled={false}
            selected={primaryBarSelection?.name === item.name && !secondaryBarHidden}
            name={formatCardName(item.name, getLabel(item))}
            onClick={() => {
              makeSessionRequest("selected_card", { cardName: item.name });
              handleClick(item);
            }}
            label={getLabel(item)}
          />
        </div>
      ))}
    </div>
  );
}
