import React, { useState } from "react";
import logo from "../assets/logo.png";
import useConfiguration from "../hooks/useConfiguration";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import {
  configDataConstants,
  tableInfoConstants,
} from "../store/actions/types";
import useUserConfiguration from "../utils/updateUserConfiguration";
import { tableTypeConstants } from "../store/actions/types";
import { ConfData3 as workStation } from "../const/const";
import { ConfData3 as meetingTables } from "../const/meetingTables";
import { ConfData3 as confTables } from "../const/conferenceTables";
import { ConfData3 as discussionTables } from "../const/discussionTables";
import { ConfData3 as cabinTables } from "../const/cabinTablesNew";
import { ConfData3 as neoWorkstation } from "../const/neoWorkstations";
import { makeSessionRequest } from "../utils/makeSessionRequest";
type Props = {
  setShowCategorySelector: (show: boolean) => void;
  setIsStartingFromScratch: (
    isStartingFromScratch: boolean | undefined
  ) => void;
  isStartingFromScratch: boolean | undefined;
};

export default function CategorySelector({
  setShowCategorySelector,
  setIsStartingFromScratch,
  isStartingFromScratch,
}: Props) {
  const { updateConfiguration } = useConfiguration();
  const { updateUserConfiguration } = useUserConfiguration();
  const configData = useAppSelector((state) => state.configData.original);
  const [tableType, setTableType] = useState<undefined | string>("Desking WKS");

  const System: any = configData[0];
  const dispatch = useAppDispatch();

  const handleOptionSelection = (obj: any) => {
    updateConfiguration(System.name, [{ ...obj }]);
    updateUserConfiguration(System.name, obj);
    setShowCategorySelector(false);
  };

  const Card = ({ name, thumbnail }: { name: string; thumbnail: string }) => (
    <div
      className="bg-white filter hover:drop-shadow-c1 mr-4 mb-4 w-30  p-2 rounded-md flex flex-col items-center cursor-pointer"
      onClick={() => {
        dispatch({ type: tableInfoConstants.SET_TABLE_INFO, payload: name });
      }}
    >
      <img
        src={thumbnail}
        alt=""
        className="h-28 w-28 xl:w-36 xl:h-36 2xl:h-36 2xl:w-48 object-cover rounded-md mb-3"
      />
      <h5 className="text-base xl:text-lg mb-2">{name}</h5>
    </div>
  );

  function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function (txt: string) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const allTables = [
    {
      name: "Desking WKS",
      onclick: () => {
        setTableType("Desking WKS");
        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: workStation,
        });
        // dispatch({
        //   type: configDataConstants.RESET_CURENT_CONFIG_DATA,
        // });
      },
    },
    {
      name: "Meeting Table",
      onclick: () => {
        setTableType("Meeting Table");

        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: meetingTables,
        });
      },
    },
    {
      name: "Conference Table",
      onclick: () => {
        setTableType("Conference Table");

        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: confTables,
        });
      },
    },
    {
      name: "Discussion Table",
      onclick: () => {
        setTableType("Discussion Table");

        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: discussionTables,
        });
      },
    },
    {
      name: "Cabin Table",
      onclick: () => {
        setTableType("Cabin Table");

        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: cabinTables,
        });
      },
    },
    {
      name: "Neo WKS",
      onclick: () => {
        setTableType("Neo WKS");

        dispatch({
          type: configDataConstants.SET_CONFIG_DATA,
          payload: neoWorkstation,
        });
      },
    },
  ];

  return (
    <div className="flex flex-col items-center p-4">
      <img src={logo} alt="" className="h-10 object-contain mb-6" />

      {isStartingFromScratch === undefined ? (
        <div className="bg-white shadow-xl px-5 py-6 rounded-lg flex flex-col justify-between items-center">
          <h3 className="font-bold mb-4 text-sm xl:text-2xl">
            Do you want to start from scratch?
          </h3>
          <div className="flex justify-center items-center w-full text-sm xl:text-2xl">
            <button
              className="px-2 py-1 bg-black text-white rounded-md"
              onClick={() => {
                setIsStartingFromScratch(true);
                makeSessionRequest("starting_from_scratch");
                dispatch({
                  type: configDataConstants.SET_CONFIG_DATA,
                  payload: workStation,
                });
              }}
            >
              Yes
            </button>
            <button
              className="ml-5 px-2 py-1 bg-black text-white rounded-md"
              onClick={() => {
                makeSessionRequest("not_starting_from_scratch");
                setIsStartingFromScratch(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div className="w-full flex flex-row items-center justify-center">
            {allTables.map((table, index) => {
              return (
                <h6
                  className={`text-sm lg:text-xl xl:text-2xl font-bold mb-4 mx-3 p-3 rounded-lg cursor-pointer ${
                    tableType === table.name
                      ? "bg-white shadow-lg border-2 border-gray-100"
                      : "border-2 border-gray-100"
                  }`}
                  onClick={() => {
                    table.onclick();
                    makeSessionRequest(`selected_table_type`, {
                      table: table.name,
                    });
                  }}
                  key={index}
                >
                  {table.name}
                </h6>
              );
            })}
          </div>

          <div className="flex flex-col items-center p-4">
            <h6 className="text-xl lg:text-2xl xl:text-3xl font-bold mb-14">
              CREATE YOUR {tableType?.toUpperCase()}
            </h6>

            <div className="flex justify-center items-center w-full">
              <div
                className={`grid gap-10 justify-center px-24 xl:px-12 ${
                  System.options.length > 1 ? "grid-cols-2" : "grid-cols-1"
                }`}
              >
                {System.options.map((option: any) => (
                  <div
                    key={option.name}
                    style={{ minWidth: "27rem" }}
                    className="ml-8 shadow-lg rounded-lg pl-5"
                  >
                    <h3 className="font-bold mb-4 text-sm xl:text-xl pl-5">
                      {toTitleCase(option.name)}
                    </h3>
                    <div
                      className={`grid ${
                        option.options.length >= 8 ||
                        option.options.length === 4
                          ? "grid-cols-4"
                          : "grid-cols-3"
                      }`}
                    >
                      {option.options.map((option2: any) => (
                        <div
                          key={option2.name}
                          onClick={() => {
                            makeSessionRequest("selected_system", {
                              systemType: option.name,
                              system: option2.name,
                            });
                            handleOptionSelection({
                              name: option.name,
                              option: option2,
                            });
                            dispatch({
                              type: tableTypeConstants.SET_TABLE_TYPE,
                              payload: tableType,
                            });
                          }}
                        >
                          <Card
                            key={option2.name}
                            name={option2.name}
                            thumbnail={option2.thumbnail}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
