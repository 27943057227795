export const configurationConstants = {
  SET_CONFIGURATION: "SET_CONFIGURATION",
  UPDATE_CONFIGURATION2: "UPDATE_CONFIGURATION2",
  DELETE_PROPERTY: "DELETE_PROPERTY",
  RESET_CONFIGURATION: "RESET_CONFIGURATION",
  UPDATE_SELECTION_STATUS: "UPDATE_SELECTION_STATUS",
  SET_PRIMARY_BAR_SELECTION: "SET_PRIMARY_BAR_SELECTION",
  DISABLE_FEATURES: "DISABLE_FEATURES",
  ENABLE_FEATURES: "ENABLE_FEATURES",
  DISABLE_OPTIONS: "DISABLE_OPTIONS",
  ENABLE_OPTIONS: "ENABLE_OPTIONS",
};

export const configDataConstants = {
  SET_CONFIG_DATA: "SET_CONFIG_DATA",
  SET_CURRENT_CONFIG_DATA: "SET_CURRENT_CONFIG_DATA",
  RESET_CURENT_CONFIG_DATA: "RESET_CURENT_CONFIG_DATA",
};

export const userConstants = {
  SET_USER: "SET_USER",
  LOGOUT: "LOGOUT",
};

export const tableTypeConstants = {
  SET_TABLE_TYPE: "SET_TABLE_TYPE",
};

export const tableInfoConstants = {
  SET_TABLE_INFO: "SET_TABLE_INFO",
  SET_TABLE_TYPE: "SET_TABLE_TYPE",
};

export const ordersConstants = {
  SET_TOTAL_ORDERS: "SET_TOTAL_ORDERS",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  SET_PAGE_SIZE: "SET_PAGE_SIZE",
  SET_ORDERS: "SET_ORDERS",
  SET_SEARCHED_ORDERS: "SET_SEARCHED_ORDERS",
  SET_CURRENT_ORDER: "SET_CURRENT_ORDER",
  ADD_ORDER: "ADD_ORDER",
  UPDATE_ORDER: "UPDATE_ORDER",
  DELETE_ORDER: "DELETE_ORDER",
  SET_LOADING_ORDERS: "SET_LOADING_ORDERS",
};
