// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "Conference Table",
        required: false,
        options: [
          {
            name: "10 Seater - 3000W x 1200D",
            size: 4,
            Length: 3000,
            Depth: 1200,
            thumbnail: "/assets/system/conference1.png",
            productId: "628ce36caae2ecb9fbba2f0c",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
          {
            name: "10 Seater- 3000W x 1500D",
            size: 6,
            Length: 3000,
            Depth: 1500,
            thumbnail: "/assets/system/conference2.png",
            productId: "628dca57b9c2d787b4cffae7",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
          {
            name: "12 Seater - 3600W x 1200D",
            size: 8,
            Length: 3600,
            Depth: 1200,
            thumbnail: "/assets/system/conference3.png",
            productId: "628daf867a825dc7b2b3571f",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
          {
            name: "12 Seater - 3600W x 1500D",
            size: 10,
            Length: 3600,
            Depth: 1500,
            thumbnail: "/assets/system/conference4.png",
            productId: "628e24a896a068cfa6b395bd",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
          {
            name: "14 Seater - 4500W x 1200D",
            size: 12,
            Length: 4500,
            Depth: 1200,
            thumbnail: "/assets/system/conference5.png",
            productId: "628dc48e82ccb79d1f5096df",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
          {
            name: "14 Seater - 4500W x 1500D",
            size: 14,
            Length: 4500,
            Depth: 1500,
            thumbnail: "/assets/system/conference6.png",
            productId: "628e297b96a068cfa6b39698",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
        ],
      },
    ],
  },

  {
    name: "Legs",
    multiSelect: true,
    options: [
      {
        name: "Conference Table",
        required: false,
        options: [
          {
            name: "Legs",
            required: false,
            options: [
              {
                name: "Colors",
                thumbnail: "/assets/components/ColorsSharing.png",
                enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [4] }],
                    node: "Leg",
                    pid: "628ce322ab1588e60121577a",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [6] }],
                    node: "Leg",
                    pid: "628dcb9dc9871c0809ced3ae",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [8] }],
                    node: "Leg",
                    pid: "628db0e782ccb79d1f509083",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [10] }],
                    node: "Leg",
                    pid: "628e265d2ba157246cad3e6e",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [12] }],
                    node: "Leg",
                    pid: "628dc66bbe811ccb6c8566ae",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [14] }],
                    node: "Leg",
                    pid: "628e2ae896a068cfa6b39700",
                  },
                  {
                    type: show,
                    node: "beam",
                  },
                ],
              },
              {
                name: "Connect",
                thumbnail: "/assets/components/FloatSharing.png",
                disableFeatures: ["Color Options"],
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [4] }],
                    node: "Leg",
                    pid: "628ce2edab1588e601215760",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [6] }],
                    node: "Leg",
                    pid: "628dcbe71de74721e10ca1b4",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [8] }],
                    node: "Leg",
                    pid: "628db12382ccb79d1f5090a8",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [10] }],
                    node: "Leg",
                    pid: "628e26a62ba157246cad3e8f",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [12] }],
                    node: "Leg",
                    pid: "628dc6d7bd1d212cb707c34a",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [14] }],
                    node: "Leg",
                    pid: "628e2b23034d92022b839002",
                  },
                  {
                    type: show,
                    node: "beam",
                  },
                ],
              },
              {
                name: "Collaborate",
                thumbnail: "/assets/components/CollaborateSharing.png",
                disableFeatures: ["Color Options"],
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [4] }],
                    node: "Leg",
                    pid: "628ce34615096c2399103d10",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [6] }],
                    node: "Leg",
                    pid: "628dcb53741b4c653d0f61f7",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [8] }],
                    node: "Leg",
                    pid: "628db07bbfe1b84c5fecd276",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [10] }],
                    node: "Leg",
                    pid: "628e261c419b8273d75e6c94",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [12] }],
                    node: "Leg",
                    pid: "628dc61c82ccb79d1f509741",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [14] }],
                    node: "Leg",
                    pid: "628e2a742ba157246cad3f8f",
                  },
                  {
                    type: show,
                    node: "beam",
                  },
                ],
              },
              {
                name: "Synergy",
                thumbnail: "/assets/components/SynergySharing.png",
                disableFeatures: ["Color Options"],
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [4] }],
                    node: "Leg",
                    pid: "628cd541eb1f4614bfda7c6a",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [6] }],
                    node: "Leg",
                    pid: "628dccfe8bc5143f91113a8d",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [8] }],
                    node: "Leg",
                    pid: "628db23a7a825dc7b2b3584a",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [10] }],
                    node: "Leg",
                    pid: "628e27c3ad0a3c4b3e31c25f",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [12] }],
                    node: "Leg",
                    pid: "628dc7d682d593b43f08a4f7",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [14] }],
                    node: "Leg",
                    pid: "628e2c2c96a068cfa6b39775",
                  },
                  {
                    type: show,
                    node: "beam",
                  },
                ],
              },
              {
                name: "Perform",
                thumbnail: "/assets/components/PerformSharing.png",
                disableFeatures: ["Color Options"],
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [4] }],
                    node: "Leg",
                    pid: "628ce2b7aae2ecb9fbba2ec5",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [6] }],
                    node: "Leg",
                    pid: "628dcc331de74721e10ca1d2",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [8] }],
                    node: "Leg",
                    pid: "628db17145df7dccce238f4b",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [10] }],
                    node: "Leg",
                    pid: "628e270d2ba157246cad3ec8",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [12] }],
                    node: "Leg",
                    pid: "628dc72bb9c2d787b4cffa69",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [14] }],
                    node: "Leg",
                    pid: "628e2b6a034d92022b839018",
                  },
                  {
                    type: show,
                    node: "beam",
                  },
                ],
              },
              {
                name: "Switch Angular",
                thumbnail: "/assets/components/SwitchAngularSharing.png",
                disableFeatures: ["Color Options"],
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [4] }],
                    node: "Leg",
                    pid: "628ce2543613b4a4dad2ad07",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [6] }],
                    node: "Leg",
                    pid: "628dcc8b8bc5143f91113a5e",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [8] }],
                    node: "Leg",
                    pid: "628db1c645df7dccce238f6f",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [10] }],
                    node: "Leg",
                    pid: "628e27502ba157246cad3eed",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [12] }],
                    node: "Leg",
                    pid: "628dc77bb9c2d787b4cffaa0",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [14] }],
                    node: "Leg",
                    pid: "628e2ba4ac04dfb090d6e916",
                  },
                  {
                    type: hide,
                    node: "beam",
                  },
                ],
              },
            ],
          },
          {
            name: "Color Options",
            required: false,
            options: [
              {
                name: "Red",
                thumbnail: "/assets/textures/ColorsLeg/Red-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Red-fl.jpg",
                  },
                ],
              },
              {
                name: "Blue",
                thumbnail: "/assets/textures/ColorsLeg/Blue-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Blue-fl.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/ColorsLeg/Green-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Green-fl.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/ColorsLeg/Yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Yellow.jpg",
                  },
                ],
              },
              {
                name: "Ivory Nandini",
                thumbnail: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
                  },
                ],
              },
              {
                name: "White",
                thumbnail: "/assets/textures/ColorsLeg/White-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/White-fl.jpg",
                  },
                ],
              },
              {
                name: "Mustard Yellow",
                thumbnail: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/ColorsLeg/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Orange.jpg",
                  },
                ],
              },
              {
                name: "Dark Grey",
                thumbnail: "/assets/textures/ColorsLeg/Dark-grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Dark-grey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/ColorsLeg/Silver-grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Silver-grey.jpg",
                  },
                ],
              },
              {
                name: "Black",
                thumbnail: "/assets/textures/ColorsLeg/Black-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Black-fl.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Wire Management",
    multiSelect: true,
    optional: false,
    options: [
      {
        name: "Conference Table",
        required: false,
        options: [
          {
            name: "Wire Access",
            required: false,

            options: [
              {
                name: "FlipUp",
                thumbnail: "/assets/components/flipup.png",

                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [4] }], 
                    node: ["table_top"],
                    pid: "63104f101870cc71c3bbed78",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [6] }],
                    node: ["table_top"],
                    pid: "631050e5d0f0b88ad43882f1",//631052c3d8b69163e78576c2
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [8] }],
                    node: ["table_top"],
                    pid: "631051e4d8b69163e785767a", //63105378d0f0b88ad438842f
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [10] }],
                    node: ["table_top"],
                    pid: "631052c3d8b69163e78576c2", //631050e5d0f0b88ad43882f1
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [12] }],
                    node: ["table_top"],
                    pid: "63105378d0f0b88ad438842f", //631051e4d8b69163e785767a
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [14] }],
                    node: ["table_top"],
                    pid: "631053c3d8b69163e7857724",
                  },
                ],
              },
              {
                name: "Slider",
                thumbnail: "/assets/components/slider.png",

                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [4] }],
                    node: ["table_top"],
                    pid: "63103d805dd2407b580749d7",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [6] }],
                    node: ["table_top"],
                    pid: "63104a687ec8343b4d5e9768", //63104bad2aba34dc613e675d
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [8] }],
                    node: ["table_top"],
                    pid: "63104b1b5dd2407b58074d4b", //63104da73b83940f287b6c8a
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [10] }],
                    node: ["table_top"],
                    pid: "63104bad2aba34dc613e675d", //63104a687ec8343b4d5e9768
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [12] }],
                    node: ["table_top"],
                    pid: "63104da73b83940f287b6c8a", //63104b1b5dd2407b58074d4b
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [14] }],
                    node: ["table_top"],
                    pid: "63104e96dc89925e6a720b62",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Conference Table",
        required: true,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Conference Table",
        required: true,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "Featherlite White Fine",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;
