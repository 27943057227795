// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "Meeting Table",
        required: false,
        options: [
          {
            name: "4 Seater - 1800W x 900D",
            size: 4,
            Length: 1800,
            Depth: 900,
            thumbnail: "/assets/system/meeting1.png",
            productId: "628335d5b5e288bd61734e73",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
          {
            name: "6 Seater - 2100W x 1050D",
            size: 6,
            Length: 2100,
            Depth: 1050,
            thumbnail: "/assets/system/meeting2.png",
            productId: "628c8e36232d15affa1e929a",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
          {
            name: "8 Seater - 2400W x 1200D",
            size: 8,
            Length: 2400,
            Depth: 1200,
            thumbnail: "/assets/system/meeting3.png",
            productId: "628c9537e332714c1f948f4e",
            tableTop: ["table_top"],
            leftLegs: ["Leg"],
            rightLegs: ["Leg"],
          },
        ],
      },
    ],
  },
  {
    name: "Legs",
    multiSelect: true,
    options: [
      {
        name: "Meeting Table",
        required: false,
        options: [
          {
            name: "Legs",
            required: false,
            options: [
              {
                name: "Colors",
                thumbnail: "/assets/components/ColorsSharing.png",
                enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [4] }],
                    node: "Leg",
                    pid: "628b4456807b1515786dcd4d",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [6] }],
                    node: "Leg",
                    pid: "628c90a0e332714c1f948dae",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [8] }],
                    node: "Leg",
                    pid: "628c96ab2f032fac6cccc602",
                  },
                  {
                    type: show,
                    node: "beam",
                  },
                ],
              },
              {
                name: "Connect",
                thumbnail: "/assets/components/FloatSharing.png",
                disableFeatures: ["Color Options"],
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [4] }],
                    node: "Leg",
                    pid: "628b44a3bc5e03b5d2c2e50a",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [6] }],
                    node: "Leg",
                    pid: "628c911ae332714c1f948df0",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [8] }],
                    node: "Leg",
                    pid: "628c96fe03c8f7c338050ec1",
                  },
                  {
                    type: show,
                    node: "beam",
                  },
                ],
              },
              {
                name: "Collaborate",
                thumbnail: "/assets/components/CollaborateSharing.png",
                disableFeatures: ["Color Options"],
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [4] }],
                    node: "Leg",
                    pid: "628337d5a8127b59172d65b2",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [6] }],
                    node: "Leg",
                    pid: "628c8f87232d15affa1e9347",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [8] }],
                    node: "Leg",
                    pid: "628c965c232d15affa1e940e",
                  },
                  {
                    type: show,
                    node: "beam",
                  },
                ],
              },
              {
                name: "Synergy",
                thumbnail: "/assets/components/SynergySharing.png",
                disableFeatures: ["Color Options"],
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [4] }],
                    node: "Leg",
                    pid: "628c8b8b2983919d964dce59",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [6] }],
                    node: "Leg",
                    pid: "628c9430e332714c1f948ee8",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [8] }],
                    node: "Leg",
                    pid: "628c97dc7a256987dd197d9e",
                  },
                  {
                    type: show,
                    node: "beam",
                  },
                ],
              },
              {
                name: "Perform",
                thumbnail: "/assets/components/PerformSharing.png",
                disableFeatures: ["Color Options"],
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [4] }],
                    node: "Leg",
                    pid: "628b44f69a6ff1e5b561f771",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [6] }],
                    node: "Leg",
                    pid: "628c918f3b650396b703aa50",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [8] }],
                    node: "Leg",
                    pid: "628c974d03c8f7c338050ee1",
                  },
                  {
                    type: show,
                    node: "beam",
                  },
                ],
              },
              {
                name: "Switch Angular",
                thumbnail: "/assets/components/SwitchAngularSharing.png",
                disableFeatures: ["Color Options"],
                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [4] }],
                    node: "Leg",
                    pid: "62833aabdb62c8c84837ee31",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [6] }],
                    node: "Leg",
                    pid: "628c91ff2f032fac6cccc46b",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [8] }],
                    node: "Leg",
                    pid: "628c978403c8f7c338050f02",
                  },
                  {
                    type: hide,
                    node: "beam",
                  },
                ],
              },
            ],
          },
          {
            name: "Color Options",
            required: false,
            options: [
              {
                name: "Red",
                thumbnail: "/assets/textures/ColorsLeg/Red-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Red-fl.jpg",
                  },
                ],
              },
              {
                name: "Blue",
                thumbnail: "/assets/textures/ColorsLeg/Blue-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Blue-fl.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/ColorsLeg/Green-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Green-fl.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/ColorsLeg/Yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Yellow.jpg",
                  },
                ],
              },
              {
                name: "Ivory Nandini",
                thumbnail: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
                  },
                ],
              },
              {
                name: "White",
                thumbnail: "/assets/textures/ColorsLeg/White-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/White-fl.jpg",
                  },
                ],
              },
              {
                name: "Mustard Yellow",
                thumbnail: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/ColorsLeg/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Orange.jpg",
                  },
                ],
              },
              {
                name: "Dark Grey",
                thumbnail: "/assets/textures/ColorsLeg/Dark-grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Dark-grey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/ColorsLeg/Silver-grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Silver-grey.jpg",
                  },
                ],
              },
              {
                name: "Black",
                thumbnail: "/assets/textures/ColorsLeg/Black-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Black-fl.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Wire Management",
    multiSelect: true,
    optional: false,
    options: [
      {
        name: "Meeting Table",
        required: false,
        options: [
          {
            name: "Wire Access",
            required: false,

            options: [
              {
                name: "FlipUp",
                thumbnail: "/assets/components/flipup.png",

                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [4] }],
                    node: ["table_top"],
                    pid: "6310966405d2bb31debb8084",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [6] }],
                    node: ["table_top"],
                    pid: "631096de05d2bb31debb80b7",//631052c3d8b69163e78576c2
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [8] }],
                    node: ["table_top"],
                    pid: "63109754ba0e1cd7c476b210", //63105378d0f0b88ad438842f
                  },
                ],
              },
              {
                name: "Slider",
                thumbnail: "/assets/components/slider.png",

                rules: [
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [4] }],
                    node: ["table_top"],
                    pid: "63109407ba0e1cd7c476b0f8",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [6] }],
                    node: ["table_top"],
                    pid: "6310950068b8d0c76093d9ef", //63104bad2aba34dc613e675d
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "noOfSeats", values: [8] }],
                    node: ["table_top"],
                    pid: "63109558ba0e1cd7c476b143", //63104da73b83940f287b6c8a
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Meeting Table",
        required: true,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "Meeting Table",
        required: true,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "Featherlite White Fine",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;
