export const pedestalConstants = {
  sharedWorkstations: {
    front: {
      pos: {
        x: -0.315,
        y: 0,
        z: 0.427,
      },
      increment: 1.204,
      rotation: {
        x: 0,
        y: 0,
        z: 0,
      },
    },
    back: {
      pos: {
        x: 0.323,
        y: 0,
        z: -0.427,
      },
      increment: 1.194,
      rotation: {
        x: 0,
        y: 180,
        z: 0,
      },
    },
  },
  nonSharedWorkstations: {
    front: {
      pos: {
        x: -0.305,
        y: 0,
        z: 0.157,
      },
      increment: 1.20,
      rotation: {
        x: 0,
        y: 0,
        z: 0,
      },
    },
  },
};
