import React from "react";
import { Product } from "../types/types";

const BomTable = ({ bom }: { bom: Product["bom"] }) => {
  return (
    <div>
      <h1 className="mb-2">BOM:</h1>
      <table>
        <thead>
          <tr className="text-left border">
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Quantity</th>
            <th className="px-4 py-2">Price</th>
          </tr>
        </thead>
        <tbody className="border">
          {bom &&
            bom.map((item) => (
              item.quantity!==0 ? <tr key={item.name} className="border">
                <td className="px-4 py-1">{item.name}</td>
                <td className="px-4 py-1">{item.quantity}</td>
                <td className="px-4 py-1">{item.price}</td>
              </tr> : null
            ))}
        </tbody>
      </table>
      {/* <div>
        Type:<span className="font-bold ml-6">{bom?.totalPrice}</span>
      </div> */}
    </div>
  );
};

export default BomTable;
